import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';
import { apiAuth } from 'libs/utils/api';
import { notifyError, notifyOk as notify } from 'libs/utils/Toaster';
import { useHistory } from 'react-router-dom';
import { ISelected } from '../ConfigureCheckoutWidgetForm';
import { TINYMCE_API_KEY } from '../../config';
import { editorConfig } from '../../constants';
import styles from './ConfigureProductWidgetForm.module.css';
import Panel from '../Panel/Panel';
import { Button } from '../FormElements';
import { ButtonDisplay, useButton } from '../FormElements/Button';
import Modal from '../../libs/utils/Modal';

interface IConfigureProductWidgetForm {
  selectedBnpl?: ISelected;
  isExistingProvider: boolean;
  recordId: string;
}

const ConfigureProductWidgetForm: React.FC<IConfigureProductWidgetForm> = ({ selectedBnpl: propsSelectedBnpl, isExistingProvider, recordId: propRecordId }) => {
  const [selectedBnpl, setSelectedBnpl] = useState<ISelected>(propsSelectedBnpl ?? {});
  const [editorHTML, setEditorHTML] = useState('');
  const [showTagsModal, setShowTagsModal] = useState(false);
  const editorRef = useRef(null);
  const { t } = useTranslation();
  const { setButtonState } = useButton();
  const history = useHistory();

  useEffect(() => {
    async function initState() {
      const { providerName, currency } = selectedBnpl;
      if (providerName && currency && isExistingProvider) {
        const bnplConfig = await getBNPLConfig(providerName, currency);
        setSelectedBnpl(bnplConfig);
        if (bnplConfig.productDetailsConfig?.content) {
          setEditorHTML(bnplConfig.productDetailsConfig?.content);
        }
      }
    }
    initState();
  }, []);

  // Get BNPL configuration
  // eslint-disable-next-line consistent-return
  const getBNPLConfig = async (provName: string, curr: string) => {
    try {
      let response;
      if (curr) {
        response = await apiAuth.get(
          `/merchant/bnplprovider/details/${provName}?curr=${curr}`,
        );
      } else {
        response = await apiAuth.get(`/merchant/bnplprovider/details/${provName}`);
      }
      return response.data;
    } catch (e) {
      console.log(e);
    }
  };

  const handleAPISubmit = async (e: any) => {
    e.preventDefault();
    try {
      setButtonState(true);
      const payload: Record<string, any> = {
        recordId: selectedBnpl?.recordId || propRecordId,
        productDetailsConfig: {
          content: editorHTML,
        },
      };
      await apiAuth.put('/merchant/bnplprovider/update', payload);
      notify('Rules updated for provider successfully');
      setButtonState(false);
        setTimeout(() => {
          history.push('/app/bnpl-management');
        }, 2000);
    } catch (error) {
      setButtonState(false);
      notifyError('Error updating rules for provider');
    }
  };

  return selectedBnpl ? (
    <div className="shadowed-container">
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <div className={styles.pageTitle}>4. {t('Product Details Widget Configuration ')}</div>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={7}
        >
          <Panel
            contentClassName={styles.panel}
            title="Edit Widget Style"
          >
            <div className={styles.formTitle}>Text and Layout</div>
            <div className={styles.titleBorder} />
            <div className={styles.subTitle}>
              Edit the default text and layout for this payment method product details widget by adding
              in custom content.
            </div>
            <div className={styles.editorTitle}>
              <div>
                <span className={styles.editorTitleBold}>
                  Dynamic Tags
                </span>
                <span className={styles.editorTitleFaded}>
                  Star Rating:
                </span>
                <span className={styles.editorTitleTag}>%star</span>
                <button
                  className={styles.editorTitleLink}
                  onClick={() => setShowTagsModal(true)}
                  type="button"
                >
                  View More Tags
                </button>
              </div>
              <div>
                <span className={styles.editorLearnMoreCircle}>?</span>
                <span className={styles.editorLearnMoreText}>Learn more</span>

              </div>
            </div>
            <Modal
              data-testid="tags-modal"
              hide={() => setShowTagsModal(false)}
              isModalShowing={showTagsModal}
              modalWidth={444}
              showClose
            >
              <div className={styles.tagsModalContainer}>
                <div className={styles.tagsModalTitle}>Dynamic Tags</div>
                <div className={styles.tagsModalBody}>
                  <div className={styles.tableHeaderGrid}>
                    <div>Tag</div>
                    <div>Example Preview</div>
                  </div>
                  <div className={styles.tableBodyGrid}>
                    <div className={styles.tableRow}>
                      <div>
                        <span className={styles.tableLabel}>Star Rating: </span>
                        <span className={styles.tableTag}>%star </span>
                      </div>
                      <div className={styles.tableValue}>
                        <div>
                          <img
                            alt="Star rating"
                            className={styles.bnplStar}
                            src="/full-star.png"
                          />
                          <img
                            alt="Star rating"
                            className={styles.bnplStar}
                            src="/full-star.png"
                          />
                          <img
                            alt="Star rating"
                            className={styles.bnplStar}
                            src="/full-star.png"
                          />
                          <img
                            alt="Star rating"
                            className={styles.bnplStar}
                            src="/full-star.png"
                          />
                          <img
                            alt="Star rating"
                            className={styles.bnplStar}
                            src="/empty-star.png"
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.tableRow}>
                      <div>
                        <span className={styles.tableLabel}>Interest Rate: </span>
                        <span className={styles.tableTag}>%interest </span>
                      </div>
                      <div className={styles.tableValue}>0%</div>
                    </div>
                    <div className={styles.tableRow}>
                      <div>
                        <span className={styles.tableLabel}>Payment Amount: </span>
                        <span className={styles.tableTag}>%amount </span>
                      </div>
                      <div className={styles.tableValue}>£2.00</div>
                    </div>
                    <div className={styles.tableRow}>
                      <div>
                        <span className={styles.tableLabel}>Payment Duration: </span>
                        <span className={styles.tableTag}>%duration </span>
                      </div>
                      <div className={styles.tableValue}> x4 Weekly</div>
                    </div>
                    <div className={styles.tableRow}>
                      <div>
                        <span className={styles.tableLabel}>Late Fees: </span>
                        <span className={styles.tableTag}>%latefees </span>
                      </div>
                      <div className={styles.tableValue}> £1.00</div>
                    </div>
                    <div className={styles.tableRow}>
                      <div>
                        <span className={styles.tableLabel}>Customer Fees: </span>
                        <span className={styles.tableTag}>%customerfees </span>
                      </div>
                      <div className={styles.tableValue}> None</div>
                    </div>
                    <div className={styles.tableRow}>
                      <div>
                        <span className={styles.tableLabel}>iFrame Content: </span>
                        <span className={styles.tableTag}>%iframe </span>
                      </div>
                      <div className={styles.tableValue}> &nbsp;</div>
                    </div>
                    <div className={styles.tableRow}>
                      <div>
                        <span className={styles.tableLabel}>Terms and Conditions: </span>
                        <span className={styles.tableTag}>%termsconditions </span>
                      </div>
                      <div className={styles.tableValue}> &nbsp;</div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
            <Editor
              apiKey={TINYMCE_API_KEY}
              init={editorConfig}
              onEditorChange={() => setEditorHTML(editorRef.current.getContent())}
              onInit={(evt, editor) => {
                  editorRef.current = editor;
                }}
              value={editorHTML}
            />
            <Button
              display={ButtonDisplay.NextButton}
              onClick={handleAPISubmit}
              text={t('Save & Continue')}
              type="submit"
            />
          </Panel>
        </Grid>
        <Grid
          item
          xs={5}
        >
          {/*  Widget Display Preview Here */}
        </Grid>
      </Grid>
    </div>
  )
    : null;
};

export default ConfigureProductWidgetForm;
