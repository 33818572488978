import React, {
 FunctionComponent, useEffect, useRef, useState,
} from 'react';
import { ProfileContext } from 'contexts/ProfileContext.context';
import './DashboardPagesLayout.css';
import { apiAuth } from 'libs/utils/api';
import Storage from 'services/storage.service';
import SideBar from './SideBar/SideBar';
import SkeletanTopBarLoader from './Loader/SkeletanTopBarLoader';

const DashboardPagesLayout: FunctionComponent = (props: { children?: React.ReactNode }) => {
  const [profileDetail, setProfileDetail] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const mountedRef = useRef(true);
  useEffect(() => {
    getProfileDetail();
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const getProfileDetail = async () => {
    try {
      const response = await apiAuth.get('/users/profile');
      const { data } = response;
      if (response.status === 401) {
        // logout();
      } else if (mountedRef.current) {
        Storage.setItem('cachedProfileDetail', JSON.stringify(data));
        setProfileDetail(data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log({ error });
    }
  };
  return (
    <>
      {isLoading ? <SkeletanTopBarLoader /> : (
        <ProfileContext.Provider value={{ profileDetail, setProfileDetail }}>
          <main className="main-content">
            <div className="sidebar-wraper">
              <div className="sidebar-content">
                <SideBar />
              </div>
            </div>

            <div className="dashboard-content">
              <article>{props.children}</article>
            </div>
          </main>
        </ProfileContext.Provider>
    )}
    </>
  );
};

export default DashboardPagesLayout;
