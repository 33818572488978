import axios from 'axios';

import Storage from '../../services/storage.service';
import { authService } from '../../services/auth.service';

export default axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const token = Storage.getToken();

export const getLoggedInUser = () => {
  return Storage.getItem('user') && JSON.parse(Storage.getItem('user'));
};

export const apiAuth = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { Authorization: `Bearer ${token}` },
});

apiAuth.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      const { pathname } = window.location;
      if (pathname.includes('/auth')) {
        return Promise.reject(error);
      }

      authService.login();
      return Promise.reject(error);
    }
    if (error.response && error.response.status === 500) {
      if (error && error.response && error.response.data && error.response.data.status) {
        const errormsg = {
          message: error.response
            ? error.response.data?.message
            : 'Error processing request.',
        };
        console.log(errormsg);
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  },
);

export const apiAuthNoInterceptor = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { Authorization: `Bearer ${token}` },
});
