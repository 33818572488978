import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Toaster.css';

toast.configure(1000);
export const notifyOk = (message, key = null) => {
  return (
    toast.success(message),
    {
      toastId: key,
    }
  );
};
export const notifyError = (message, key = null, toastOptions = {}) => {
  return (
    toast.error(message, toastOptions),
    {
      toastId: key,
    }
  );
};
