import Auth from './identityServer.service';
import storage from './storage.service';

const isAuthenticated = () => {
  const accessToken = storage.getItem('access_token');
  return !!accessToken;
};

function login() {
  const auth = new Auth();
  auth.signinRedirect();
}

function logout() {
  const auth = new Auth();
  auth.signoutRedirectCallback();
}

async function handleAuthentication() {
  try {
    const auth = new Auth();
    await auth.signinRedirectCallback();
    localStorage.setItem('isLoggedIn', 'true');
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

function getUser() {
  return localStorage.getItem('userId');
}

async function getStateTokenFromInitialState() {
  const auth = new Auth();
  const latestStateToken = await auth.getStateTokenFromInitialState();
  return latestStateToken;
}

export const authService = {
  login,
  logout,
  handleAuthentication,
  getUser,
  isAuthenticated,
  getStateTokenFromInitialState,
};
