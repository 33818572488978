import React, { FC, useState } from 'react';
import { composeClasses } from '../../../libs/utils';
import './Button.css';

export const useButton = () => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  function setButtonState(state: boolean) {
    setIsButtonLoading(state);
  }

  return {
    isButtonLoading,
    setButtonState,
  };
};

export enum ButtonDisplay {
  FillAvailable = 'btn-block',
  FitContent = 'btn-inline',
  NextButton = 'btn-next',
  InvertedNext = 'btn-next-inverted',
  InvertedSuccess = 'btn-next-inverted-success',
  InvertedDanger = 'btn-next-inverted-danger',
  Danger = 'btn-next-danger',
  DangerRounded = 'btn-next-danger-rounded',
  Success = 'btn-next-success',
  Dark = 'btn-dark',
  Blue = 'btn-blue',
  Transparent = 'btn-transparent',
}

interface IOpttyButton
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  text: string | React.ReactNode;
  textBold?: boolean;
  display?: ButtonDisplay;
  icon?: React.ReactNode;
}

const Button: FC<IOpttyButton> = ({
  type = 'button',
  textBold = true,
  display = ButtonDisplay.FillAvailable,
  ...props
}) => {
  /* eslint-disable react/button-has-type */
  return (
    <button
      className={composeClasses('button', display, textBold ? 'text-bolder' : '')}
      type={type}
      {...props}
    >
      {props.icon ? (
        <span className="btn-with-icon">
          <i className="icon">{props.icon}</i> <span className="text">{props.text}</span>
        </span>
      ) : (
        props.text
      )}
    </button>
  );
  /* eslint-enable react/button-has-type */
};

export default Button;
