import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Switch from '@material-ui/core/Switch';
import { notifyError, notifyOk as notify } from 'libs/utils/Toaster';
import { apiAuth } from 'libs/utils/api';
import { copyTextToClipboard } from 'libs/utils/clipboard';
import styles from './ManageWebhookForm.module.css';
import {
     Button,
  FormControlBox,
  SingleLineTextInput,
} from '../FormElements';
import { InputStyling } from '../FormElements/SingleLineTextInput';
import { ButtonDisplay } from '../FormElements/Button';

interface IFormState {
  callbackEnabled: boolean;
  callbackRawResponse: boolean;
  callbackUrl: string;
  errorMessage: string;
}

const ManageWebhookForm = (props: any) => {
    const { t } = useTranslation();
    const { selectedBnpl } = props;
    const [formState, setFormState] = useState<IFormState>({
        callbackEnabled: selectedBnpl.callbackEnabled ?? false,
        callbackRawResponse: selectedBnpl.callbackRawResponse ?? false,
        callbackUrl: selectedBnpl.callbackUrl ?? null,
        errorMessage: '',
    });

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = event.target;

      const stateUpdate: Record<string, any> = {
        [name]: checked,
      };

      if (name === 'callbackEnabled') {
        stateUpdate.callbackUrl = '';
        stateUpdate.callbackRawResponse = false;
      }
      setFormState((prevState) => ({
        ...prevState,
        ...stateUpdate,
      }));
    };

    const handleChange = (e: any) => {
        const { name, value } = e.target;

        setFormState((prevState) => ({
        ...prevState,
        [name]: value,
        }));
    };

    const handleCopyClick = (copyText: string) => {
      if (!copyText) {
        return;
      }
      copyTextToClipboard(copyText)
      .then(() => {
         notify('Copied content to clipboard');
        })
      .catch(() => {
        notify('Unable to copy content to clipboard');
      });
  };

    const isFormValid = (): boolean => {
        const { callbackEnabled, callbackUrl } = formState;
        return !(callbackEnabled && callbackUrl === '');
    };

    const handleAPIUpdate = async (e: any) => {
    e.preventDefault();
    try {
        const {
            callbackEnabled,
            callbackRawResponse,
            callbackUrl,
        } = formState;

        const payload: Record<string, any> = {
            recordId: props.recordId,
            callbackEnabled,
            callbackRawResponse,
            callbackUrlUseDefault: !callbackEnabled,
            providerName: props.selectedBnpl?.providerName || props.selectedBnpl?.provider,
            callbackUrl: callbackEnabled ? callbackUrl : null,
        };

        await apiAuth.put('/merchant/bnplprovider/update', payload);
        notify('Webhook settings updated for provider successfully');
        setFormState((prevState) => ({
            ...prevState,
            success: true,
        }));

        const reload = new CustomEvent('component-render', {
            detail: {
            formState: {
                ...formState,
            },
            },
        });
        window.dispatchEvent(reload);
    } catch (error: any) {
        if (error.response.status === 400) {
            const { data: { message } } = error.response;
            notifyError(message[0]);
            setFormState((prevState) => ({
              ...prevState,
              errorMessage: message,
            }));
        } else {
            notifyError('Error updating webhook setting for provider');
            setFormState((prevState) => ({
              ...prevState,
              errorMessage: 'Unable to update webhook setting for provider',
            }));
        }
    }
  };

    return props.selectedBnpl ? (
      <div
        className="shadowed-container"
        data-testid="manage-webhook-div"
      >
        <div className={styles.formTitle}>5. {t('Webhooks/Callback Setting')}</div>
        <form onSubmit={(e) => handleAPIUpdate(e)}>

          <div className={`${styles.formRow} ${styles.semiGroupDiv}`}>
            <div className={styles.flex}>
              <div className={styles.webhookLabel}>ON/OFF</div>
              <Switch
                checked={formState.callbackEnabled}
                inputProps={{ 'aria-label': 'Interest available checkbox' }}
                name="callbackEnabled"
                onChange={handleSwitchChange}
              />
            </div>
          </div>
          { formState.callbackEnabled ? (
            <div className={`${styles.formRow} ${styles.semiGroupDiv}`}>
              <div className={styles.flex}>
                <div className={styles.webhookLabel}> Raw Payment Methods Response</div>
                <Switch
                  checked={formState.callbackRawResponse}
                  inputProps={{ 'aria-label': 'Interest available checkbox' }}
                  name="callbackRawResponse"
                  onChange={handleSwitchChange}
                />
              </div>
            </div>
          ) : null}

          { formState.callbackEnabled ? (
            <div className={styles.semiGroupDiv}>
              <div className={styles.inputFlex}>
                <div className={`${styles.webhookLabel} ${styles.inputFlexDiv}`}> URL</div>
                <FormControlBox
                  className="text-bolder"
                  controlClassName=""
                  htmlFor="callbackUrl"
                >
                  <SingleLineTextInput
                    copy
                    copyTextToClipboard={handleCopyClick}
                    data-testid="bnpl-webhook"
                    id="callbackUrl"
                    name="callbackUrl"
                    onChange={(e: any) => handleChange(e)}
                    required
                    styling={InputStyling.Full}
                    type="text"
                    value={formState.callbackUrl || ''}
                  />
                </FormControlBox>
              </div>
            </div>
        ) : null}
          <div>
            <div className={styles.submitForm}>
              <Button
                disabled={!isFormValid()}
                display={ButtonDisplay.NextButton}
                text={t('Save Changes')}
                type="submit"
              />
            </div>
          </div>
        </form>

      </div>
    ) : null;
};

export default ManageWebhookForm;
