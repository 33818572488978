import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { composeClasses } from 'libs/utils';
import ReactHtmlParser from 'react-html-parser';
import { strippedBnplName } from 'libs/utils/bnpl-exceptions';
import { ArrowRight } from '@material-ui/icons';
import bnplData from '../../data/support-signup-content.json';
import styles from './SignUpSupport.module.css';

export enum ContentOptions {
  Support = 'support',
  SignUp = 'signup',
}

type SupportProps = {
  selectedBnpl: {
    provider: string,
    currency: string,
    imageUrl: string
  },
  type: ContentOptions
}

const SignUpSupport = ({ selectedBnpl, type }: SupportProps) => {
  const { t } = useTranslation();
  const { provider, currency, imageUrl } = selectedBnpl;
  const [data, setData] = React.useState<Record<string, any> | null>(null);

  React.useEffect(() => {
    const currentBnpl = (bnplData as Record<string, any>)[strippedBnplName(provider)];
    if (currentBnpl && currentBnpl[currency]) {
      setData(currentBnpl[currency]);
    }
  }, []);

  const getBodyDisplay = (provider: string, type: ContentOptions, data: Record<string, any>) => {
    if (type === ContentOptions.SignUp) {
      return (
        <>
          <p className={styles.body}>{ReactHtmlParser(data.signupDescription)}</p>
          {getCoverageDisplay(data)}
          {data.signupLink && (
            <a
              className={styles.action}
              href={data.signupLink}
              rel="noreferrer"
              target="_blank"
            >{`start ${strippedBnplName(provider)} application`} <ArrowRight />
            </a>
          )}
        </>
      );
    }
    return (
      <>
        <p className={styles.body}>{ReactHtmlParser(data.supportDescription)}</p>
        {getCoverageDisplay(data)}
        {data.supportLink && (
          <a
            className={styles.action}
            href={data.supportLink}
            rel="noreferrer"
            target="_blank"
          >{`visit ${strippedBnplName(provider)} support`} <ArrowRight />
          </a>
        )}
      </>
    );
  };

  const getCoverageDisplay = (data: Record<string, any>) => {
    if (data.coverageDescription) {
      return (
        <b>
          <br />
          <p className={styles.body}>{ReactHtmlParser(data.coverageDescription)}</p>
        </b>
);
    }
    return null;
  };

  const getHeaderDisplay = (type: ContentOptions) => {
    if (type === ContentOptions.SignUp) {
      return (
        <>
          <span className={styles[type]}>{t('Sign Up and Apply to')}</span>
          <img
            alt={provider}
            className={styles.logo}
            src={imageUrl}
          />
        </>
      );
    }

    return (
      <>
        <img
          alt={provider}
          className={styles.logo}
          src={imageUrl}
        />
        <span className={styles[type]}>{t('Help & Support')}</span>
      </>
    );
  };

  return data && data[`${type}Description`] ? (
    <div className={styles.supportSignupContainer}>
      <div className={composeClasses(styles.content, 'shadowed-container')}>
        <div className={styles.header}>
          {getHeaderDisplay(type)}
        </div>
        {getBodyDisplay(provider, type, data)}
      </div>
    </div>
  ) : <span />;
};

export default SignUpSupport;
