import React from 'react';
import './SkeletalScreenLoader';

const SkeletanTopBarLoader = () => {
  return (
    <>
      <div className="blank-sidebar-content" />
    </>
  );
};

export default SkeletanTopBarLoader;
