const tabExceptions = ['AFTERPAY', 'CLEARPAY'];

/**
 * Strip the contents before the underscore / _ i.e. AFTERPAY from AFTERPAY_AU
 */
export const strippedBnplName = (providerName: string): string => {
  if (providerName.includes('_')) {
    return providerName.substr(0, providerName.indexOf('_'));
  }
  return providerName;
};

/**
 * Is the provided provider name one of the BNPLs that requires an exception
 */
export const isBnplException = (providerName: string): boolean => {
  if (tabExceptions.includes(strippedBnplName(providerName))) {
    return true;
  }

  return false;
};
