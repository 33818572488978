import React from 'react';
import { Link } from 'react-router-dom';
import './ProfileDropDown.css';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Feature from 'components/Feature';
import SessionTimeout from 'components/SessionTimeout';
import { authService } from 'services/auth.service';
import { ReactComponent as PersonIcon } from '../../../assets/images/svg/account.svg';
import { ReactComponent as ArrowDropDownIcon } from '../../../assets/images/svg/dropdown.svg';
import { ReactComponent as ArrowRightIcon } from '../../../assets/images/svg/next.svg';

function handleLogout(e?: any) {
  e?.preventDefault();
  return authService.logout();
}

const ProfileDropDown = ({ firstName }: { firstName: string }) => {
  return (
    <div className="profile-dropdown">
      <SessionTimeout
        loggedIn
        logout={handleLogout}
      />
      <div className="dropdown">
        <div className="dropdown-btn">
          <PersonIcon
            className="profile-icon"
            fontSize="large"
          />
          <div
            className="greetings"
            data-testid="greetings"
          >
            Hi, <strong className="text-capitalize">{firstName}!</strong>
          </div>
          <ArrowDropDownIcon className="arrow-dropdown" />
        </div>
        <div className="profile-dropdown-content">
          <Feature name="account.account-details-dropdown">
            <Link
              className="d-link"
              to="/app/profile"
            >
              <PersonIcon /> <span>View Account</span> <ArrowRightIcon />
            </Link>
          </Feature>
          <Link
            className="d-link"
            onClick={handleLogout}
            to="/app"
          >
            <ExitToAppIcon /> <span>Logout</span> <ArrowRightIcon />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProfileDropDown;
