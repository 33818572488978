// @ts-ignore
import sanitize from 'htmlsanitize';
import { IThemesType } from '../../routes/WidgetDisplay/widgets.d';
import { ColorConfig, FieldTypes } from '../../constants';
import { apiAuth } from './api';
import { IFormState } from '../../interfaces';

export const colorConfigType = (colorConfig: string) => {
  switch (colorConfig) {
    case 'primaryColor':
      return ColorConfig.primaryColor;
    case 'secondaryColor':
      return ColorConfig.secondaryColor;
    case 'buttonColor':
      return ColorConfig.buttonColor;
    case 'textColor':
      return ColorConfig.textColor;
    case 'leftSidebarBgColor':
      return ColorConfig.leftSidebarBgColor;
    case 'leftSidebarActiveColor':
      return ColorConfig.leftSidebarActiveColor;
    case 'leftSidebarHoverColor':
      return ColorConfig.leftSidebarHoverColor;
    case 'leftSidebarTextColor':
      return ColorConfig.leftSidebarTextColor;
    case 'topBarTextColor':
      return ColorConfig.topBarTextColor;
    case 'topBarBgColor':
      return ColorConfig.topBarBgColor;
    default:
      return 0;
  }
};

/**
 * check if a literal is falsy or not
 */
const isLiteralFalsey = (variable: unknown) => {
  return variable === '' || variable === false || variable === 0;
};

/**
 * check if a variable contains a reference type (not a literal)
 */

const isPrimitive = (arg: unknown) => {
  return (
    typeof arg === 'object'
    || (typeof arg !== 'undefined' && arg !== null && typeof arg === 'function')
  );
};

/**
 * check the type name of any primitive and/or reference variable
 */
const checkTypeName = (target: unknown, type: string) => {
  let typeName = '';
  let match = false;

  if (isLiteralFalsey(target) || !isPrimitive(target)) {
    typeName = typeof target;
  } else {
    typeName = Object.prototype.toString.call(target).replace(/^\[object (.+)\]$/, '$1');
  }

  match = Boolean(typeName.toLowerCase().indexOf(type) + 1);

  return match;
};

/**
 * get the actual type of any variable
 *
 * @param {mixed} value
 * @param {String | Array | Function} type
 */
export const strictTypeOf = (value: unknown, type: string | Function | string[]) => {
  let result = false;
  /* eslint-disable-next-line no-param-reassign */
  type = type || [];

  if (typeof type === 'object') {
    if (typeof type.length !== 'number') {
      return result;
    }

    let bitPiece = 0;
    /* eslint-disable-next-line no-param-reassign */
    type = [].slice.call(type) as string[];

    type.forEach(($type) => {
      let localResult = false;
      if (typeof $type === 'function') {
        localResult = value instanceof $type;
      }
      /* eslint-disable-next-line no-bitwise */
      bitPiece |= Number(localResult || checkTypeName(value, $type));
    });
    result = Boolean(bitPiece);
  } else {
    if (typeof type === 'function') {
      result = value instanceof type;
    }
    result = result || checkTypeName(value, type as string);
  }
  return result;
};

/**
 * A function that does nothing: returns the first argument only
 *
 * @param {mixed} arg
 * @param {mixed} context
 */
export const noop = (arg: unknown, context?: unknown) => {
  return arg || context;
};
/**
 * A function that formats a query string and removes the question mark in front
 *
 * @param {String} querystring
 */
export const formatQueryString = (querystring: string): string => {
  return querystring.charAt(0) === '?' ? querystring.slice(1) : querystring;
};

/**
 * A function to group JSX class names for react elements
 *
 * @param {*} styles
 */

export const composeClasses = (...styles: (string | boolean | undefined)[]): string => styles.filter((item) => item).join(' ');

export const getAvailableWidgetThemes = async (widget: string) => {
  const availableThemes: IThemesType[] = [];
  const getAvailableThemes = await apiAuth.get('/merchant/theme');

  getAvailableThemes?.data[widget].map((theme: IThemesType) => availableThemes.push({ name: theme.name, value: theme.value }));

  return availableThemes;
};

export const takeNCharacters = (text: string, n: number) => sanitize((text || '').substr(0, n));

export enum fontOptions {
  AvenirNext = 'AvenirNext',
  ProximaNova = 'ProximaNova'
}

export enum WidgetsWithAvailableThemes {
  FOOTER = 'footer',
  PRODUCT_LISTING = 'productListing',
  PRODUCT_DETAILS = 'productDetails',
  CART = 'cart',
  CHECKOUT = 'checkout'
}

export const isFormInvalid = (formState: IFormState, maxCharacter: number) => {
  Object.values(formState.theme.properties).forEach((item) => {
    return item.options === FieldTypes.TEXT && item.value.length > maxCharacter;
  });
  return false;
};
