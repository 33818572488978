export const API_CONFIG = {
  apiUrl: process.env.REACT_APP_API_URL || '',
  nodeEnv: process.env.REACT_APP_NODE_ENV,
};

export const AUTH_CONFIG = {
  clientId: process.env.REACT_APP_IS_CLIENT_ID || '',
  callbackUrl: process.env.REACT_APP_CALLBACK_URL || '',
};

export const IDENTITY_CONFIG = {
  authority: process.env.REACT_APP_AUTH_URL,
  client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID,
  response_type: 'code',
  post_logout_redirect_uri: process.env.REACT_APP_LOGOFF_REDIRECT_URL,
  automaticSilentRenew: true, // Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
  loadUserInfo: true, // Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
  silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URL, // The URL for the page containing the code handling the silent renew.
  audience: process.env.REACT_APP_AUDIENCE, // is there a way to specific the audience when making the jwt
  scope: 'openid',
};
