import React from 'react';
import './SkeletalScreenLoader';

const SkeletanSideBarLoader = () => {
  return (
    <>
      <main className="blank-main-content">
        <div className="blank-sidebar-wraper">
          <div className="blank-sidebar-content" />
        </div>
      </main>
    </>
  );
};

export default SkeletanSideBarLoader;
