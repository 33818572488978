import websiteConfig from 'config/website_config/website_config.json';

interface IWebsiteConfig {
    favicon: string;
    title: string;
    description: string;
}

export function updateMetaAndFavicon() {
    const { hostname } = window.location;
    const config: IWebsiteConfig = (websiteConfig as { [key: string]: any })[hostname] || websiteConfig.default;

    const metaDescription = document.querySelector('meta[name="description"]');
    const faviconEl = document.getElementById('dynamic-favicon') as HTMLLinkElement;
    const appleTouchIconEl = document.querySelector('link[rel="apple-touch-icon"]') as HTMLLinkElement;

    if (metaDescription) {
        metaDescription.setAttribute('content', config.description);
    }

    if (faviconEl) {
        faviconEl.href = `${process.env.PUBLIC_URL}/${config.favicon}`;
    }

    if (appleTouchIconEl) {
        appleTouchIconEl.href = `${process.env.PUBLIC_URL}/${config.favicon}`;
    }

    document.title = config.title;
}
