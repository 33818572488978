export interface IProviderInputInterface {
    name: string,
    label: string,
    type: string,
    keyType: string,
    isRequired?: boolean
}

export type PaymentProviderConfigType = {
    [key: string]: IProviderInputInterface[]
}

export const API_KEY = 'apiKey';
export const API_SECRET = 'apiSecret';
export const PIPE_SEPARATOR = '[PIPE]';