import React, { useState, useEffect } from 'react';

type Props = {
  loggedIn: boolean
  logout: () => void
}

const MAX_DURATION = Number(process.env.REACT_APP_SESSION_TIMEOUT_DURATION) || 900;

const SessionTimeout = ({ loggedIn, logout }: Props) => {
  const [sessionTimeout, setSessionTimeout] = useState(MAX_DURATION);

  const resetSessionTimeout = () => {
    setSessionTimeout(MAX_DURATION);
  };

  useEffect(() => {
    let timer: any;

    if (loggedIn) {
      timer = setInterval(() => {
        if (sessionTimeout > 0) {
          setSessionTimeout(sessionTimeout - 1);
        } else {
          logout();
        }
      }, 1000);
    } else {
      clearInterval(timer);
    }
    window.addEventListener('mousemove', resetSessionTimeout);
    window.addEventListener('keydown', resetSessionTimeout);
    return () => {
      clearInterval(timer);
      window.removeEventListener('mousemove', resetSessionTimeout);
      window.removeEventListener('keydown', resetSessionTimeout);
    };
  }, [loggedIn, sessionTimeout]);

  return (<></>);
};

export default SessionTimeout;
