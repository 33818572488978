import React, { FC, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './modal.css';

export const useModal = () => {
  const [isModalShowing, setIsModalShowing] = useState(false);

  function toggleModal() {
    setIsModalShowing(!isModalShowing);
  }

  return {
    isModalShowing,
    toggleModal,
  };
};

interface IModal extends React.HTMLAttributes<HTMLDivElement> {
  isModalShowing: boolean;
  hide: any;
  showClose?: boolean;
  children: React.ReactNode;
  modalWidth?: number;
}

const Modal: FC<IModal> = ({
  isModalShowing = false,
  showClose = false,
  modalWidth = 500,
  ...props
}) => {
  const modalRoot = document.getElementById('modal-root');
  const el = document.createElement('div');
  useEffect(() => {
    if (modalRoot) {
      modalRoot.appendChild(el);
    }
    return () => {
      if (modalRoot) {
        modalRoot.removeChild(el);
      }
    };
  }, [modalRoot, el]);

  return isModalShowing
    ? ReactDOM.createPortal(
      <>
        <div className="modal-overlay" />
        <div
          aria-hidden
          aria-modal
          className="modal-wrapper"
          role="dialog"
          tabIndex={-1}
        >
          <div
            className="modal"
            style={{ width: `${modalWidth}px` }}
          >
            {showClose && (
            <div className="modal-header">
              <button
                aria-label="Close"
                className="modal-close-button"
                data-dismiss="modal"
                onClick={props.hide}
                type="button"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
              )}
            <div>{props.children}</div>
          </div>
        </div>
      </>,
        el,
      )
    : null;
};

export default Modal;
