interface IConditionalItems {
  children: string | React.ReactNode;
  condition: boolean | React.ReactNode;
  wrapper: Function;
}

const ConditionalWrapper = ({ condition, wrapper, children }: IConditionalItems) => {
  return condition ? wrapper(children) : children;
};

export default ConditionalWrapper;