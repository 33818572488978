import React from 'react';
import { authService } from 'services/auth.service';
import SkeletalScreenLoader from '../components/layouts/Loader/SkeletalScreenLoader';

const HandleUnauth = () => {
  authService.login();

  return <SkeletalScreenLoader />;
};

export default HandleUnauth;
