import React, {
 useContext, useEffect, useRef, useState,
} from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Feature from 'components/Feature';
import './SideBar.css';
import ReloadNavLink from 'libs/utils/ReloadNavlink';
import Storage from 'services/storage.service';
import { roles } from 'constants/roles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Collapse } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LocalActivityOutlinedIcon from '@material-ui/icons/LocalActivityOutlined';
import { ProfileContext } from 'contexts/ProfileContext.context';
import { ReactComponent as ReportIcon } from '../../../assets/images/svg/reporting.svg';
import { ReactComponent as DashboardIcon } from '../../../assets/images/svg/dashboard-icon.svg';
import { ReactComponent as PaymentsManagementIcon } from '../../../assets/images/svg/payments-management.svg';
import { ReactComponent as MerchantManagementIcon } from '../../../assets/images/svg/merchant-management.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/images/svg/settings.svg';
import { ReactComponent as WidgetsIcon } from '../../../assets/images/svg/widgets.svg';
import { ReactComponent as DemoVideoIcon } from '../../../assets/images/svg/watch-demo-video.svg';
import { ReactComponent as TrainingVideoIcon } from '../../../assets/images/svg/training-video.svg';
import { apiAuth } from '../../../libs/utils/api';
import SkeletanSideBarLoader from '../Loader/SkeletanSideBarLoader';
import { ReactComponent as PaymentTypeIcon } from '../../../assets/images/svg/payment-type.svg';

export interface ITheme {
  logo?: string | null;
  leftSidebarBgColor?: string | null;
  leftSidebarActiveColor?: string | null;
  leftSidebarHoverColor?: string | null;
  leftSidebarTextColor?: string | null;
  topBarTextColor?: string | null;
  topBarBgColor?: string | null;
}

export interface IProfileDetail {
  firstName?: string | null;
  merchantName?: string | null;
  role?: string | null;
  grandparentName?: string | null;
  parentName?: string | null;
  theme?: ITheme | null;
}

const SideBar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { profileDetail, setProfileDetail }: any = useContext(ProfileContext);
  const [loading, setLoading] = useState(false);
  const [openOpttyDirectMenu, setOpenOpttyDirectMenu] = useState(true);
  const mountedRef = useRef(true);
  const [open, setOpen] = useState(true);
  useEffect(() => {
    localStorage.setItem('redirectUri', `${location.pathname}${location.search}`);
  }, [location]);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleOpttyDirectMenuClick = () => setOpenOpttyDirectMenu(!openOpttyDirectMenu);

  // extract pathname from location
  const { pathname } = useLocation();
  let brandLogo = '';
  let content: any = '';
  if (loading) {
    content = <SkeletanSideBarLoader />;
  } else if (!loading && Object.keys(profileDetail).length) {
    const isThemeAvailable = !!profileDetail?.theme;
    const isProd = process.env.REACT_APP_NODE_ENV === 'production';
    let activeClName;
    if (isThemeAvailable) {
      brandLogo = profileDetail?.theme?.logo ? profileDetail?.theme?.logo : '';
      if (profileDetail?.theme?.leftSidebarBgColor) document.documentElement.style.setProperty('--color-left-sidebar-bg', profileDetail?.theme?.leftSidebarBgColor);
      if (profileDetail?.theme?.leftSidebarTextColor) document.documentElement.style.setProperty('--color-left-sidebar-text', profileDetail?.theme?.leftSidebarTextColor);
      if (profileDetail?.theme?.leftSidebarActiveColor) document.documentElement.style.setProperty('--color-left-sidebar-active', profileDetail?.theme?.leftSidebarActiveColor);
      if (profileDetail?.theme?.leftSidebarHoverColor) document.documentElement.style.setProperty('--color-left-sidebar-hover', profileDetail?.theme?.leftSidebarHoverColor);
      activeClName = 'menu-link theme-active';
    } else {
      activeClName = `menu-link ${isProd ? 'prod-active' : 'sandbox-active'}`;
    }
    content = (
      <div>
        <div className="brand-area">
          {brandLogo ? (
            <img
              alt="Optty-Logo"
              className="brand-logo brand-sub-logo"
              src={brandLogo}
            />
          ) : (
            <div className="brand-logo brand-sub-logo" />
          )}
        </div>
        <div className="nav-links">
          <Feature name="dashboard-and-analytics">
            <NavLink
              activeClassName={activeClName}
              className="menu-link"
              isActive={() => ['/', '/app', '/app/dashboard'].includes(pathname)
                    || /app\/dashboard\/*/.test(pathname)}
              to="/app/dashboard"
            >
              <DashboardIcon className="link-icon" /> <span>{t('Dashboard')}</span>
            </NavLink>
          </Feature>
          <Feature name="reports-and-analytics">
            <List
              component="nav"
              className="nav-list"
            >
              <NavLink
                className="nav-report m-link"
                isActive={() => ['/app/transaction-wise-report', '/app/reports'].includes(pathname)}
                to="/app/transaction-wise-report"
              >
                <ListItem
                  button
                  className="main-menu"
                  onClick={handleClick}
                >
                  <ListItemIcon className="report-icon">
                    <ReportIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Reporting"
                    className="report-text"
                  />
                  {open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                  in={open}
                  timeout="auto"
                  unmountOnExit
                  className="collapse-option"
                >
                  <List
                    className="dropdown-nav"
                    disablePadding
                  >
                    <NavLink
                      className="nav-report m-link"
                      isActive={() => ['/app/reports'].includes(pathname)}
                      to="/app/reports"
                    >
                      <ListItem
                        button
                        className="nested"
                      >
                        <ListItemIcon>
                          <FiberManualRecordIcon
                            className="dropodown-report-icon"
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary="Comparison Report"
                          className="report-text"
                        />
                      </ListItem>
                    </NavLink>
                    <NavLink
                      className="nav-report m-link"
                      isActive={() => ['/app/transaction-wise-report'].includes(pathname)}
                      to="/app/transaction-wise-report"
                    >
                      <ListItem
                        button
                        className="nested"
                      >
                        <ListItemIcon>
                          <FiberManualRecordIcon
                            className="dropodown-report-icon"
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary="Transaction Report"
                          className="report-text"
                        />
                      </ListItem>
                    </NavLink>
                    <NavLink
                      className="nav-report m-link"
                      isActive={() => ['/app/fraud-report'].includes(pathname)}
                      to="/app/fraud-report"
                    >
                      <ListItem
                        button
                        className="nested"
                      >
                        <ListItemIcon>
                          <FiberManualRecordIcon
                            className="dropodown-report-icon"
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary="Fraud report"
                          className="report-text"
                        />
                      </ListItem>
                    </NavLink>
                  </List>
                </Collapse>
              </NavLink>
            </List>
          </Feature>
          {profileDetail.role === 'grandparent' && (
            <div>
              <Feature name="dashboard-for-hierarchy">
                <NavLink
                  activeClassName={activeClName}
                  className="menu-link"
                  isActive={() => ['/app/partner-management', '/app/partner-management'].includes(pathname)
                    || /app\/partner-management\/*/.test(pathname)}
                  to="/app/partner-management"
                >
                  <MerchantManagementIcon className="link-icon" /> <span>{t('Partners Management')}</span>
                </NavLink>
              </Feature>
              <Feature name="payment-methods-management">
                <NavLink
                  activeClassName={activeClName}
                  className="menu-link"
                  isActive={() => ['/app/payment-methods-management', '/app/payment-methods-management'].includes(pathname)
                    || /app\/payment-methods-management\/*/.test(pathname)}
                  to="/app/payment-methods-management"
                >
                  <PaymentsManagementIcon className="link-icon" /> <span>{t('Payments Management')}</span>
                </NavLink>
              </Feature>
              <Feature name="payment-methods-management">
                <NavLink
                  activeClassName={activeClName}
                  className="menu-link"
                  isActive={() => ['/app/provider-integrations', '/app/provider-integrations'].includes(pathname)
                    || /app\/provider-integrations\/*/.test(pathname)}
                  to="/app/provider-integrations"
                >
                  <LocalActivityOutlinedIcon className="link-icon" /> <span>{t('Provider Integrations')}</span>
                </NavLink>
              </Feature>
              <Feature name="channel-bulk-activate">
                <NavLink
                  activeClassName={activeClName}
                  className="menu-link"
                  isActive={() => ['/app/add-channel', '/app/add-channel'].includes(pathname)
                    || /app\/add-channel\/*/.test(pathname)}
                  to="/app/add-channel"
                >
                  <ListAltIcon className="link-icon" /> <span>{t('Channel/s')}</span>
                </NavLink>
              </Feature>
            </div>
          )}
          {profileDetail.role === 'parent' && (
            <div>
              <Feature name="dashboard-for-hierarchy">
                <NavLink
                  activeClassName={activeClName}
                  className="menu-link"
                  isActive={() => ['/app/merchant-management', '/app/merchant-management'].includes(pathname)
                    || /app\/merchant-management\/*/.test(pathname)}
                  to="/app/merchant-management"
                >
                  <MerchantManagementIcon className="link-icon" /> <span>{t('Merchants Management')}</span>
                </NavLink>
              </Feature>
              <Feature name="payment-methods-management">
                <NavLink
                  activeClassName={activeClName}
                  className="menu-link"
                  isActive={() => ['/app/payment-methods-management', '/app/payment-methods-management'].includes(pathname)
                    || /app\/payment-methods-management\/*/.test(pathname)}
                  to="/app/payment-methods-management"
                >
                  <PaymentsManagementIcon className="link-icon" /> <span>{t('Payments Management')}</span>
                </NavLink>
              </Feature>
              <Feature name="payment-methods-management">
                <NavLink
                  activeClassName={activeClName}
                  className="menu-link"
                  isActive={() => ['/app/provider-integrations', '/app/provider-integrations'].includes(pathname)
                    || /app\/provider-integrations\/*/.test(pathname)}
                  to="/app/provider-integrations"
                >
                  <LocalActivityOutlinedIcon className="link-icon" /> <span>{t('Provider Integrations')}</span>
                </NavLink>
              </Feature>
              <Feature name="channel-bulk-activate">
                <NavLink
                  activeClassName={activeClName}
                  className="menu-link"
                  isActive={() => ['/app/add-channel', '/app/add-channel'].includes(pathname)
                    || /app\/add-channel\/*/.test(pathname)}
                  to="/app/add-channel"
                >
                  <ListAltIcon className="link-icon" /> <span>{t('Channel/s')}</span>
                </NavLink>
              </Feature>
            </div>
          )}
          {profileDetail.role === roles.MERCHANT && (
            <div>
              <Feature name="bnpl-management">
                <NavLink
                  activeClassName={activeClName}
                  className="menu-link"
                  isActive={() => ['/app/bnpl-management', '/app/add-merchant'].includes(pathname)
                    || /app\/merchant\/*/.test(pathname)}
                  to="/app/bnpl-management"
                >
                  <PaymentsManagementIcon className="link-icon" /> <span>{t('Payments Management')}</span>
                </NavLink>
              </Feature>
              <Feature name="fraud-detection">
                <NavLink
                  activeClassName={activeClName}
                  className="menu-link"
                  isActive={() => ['/app/fraud-detection', '/app/fraud-detection'].includes(pathname)
                    || /app\/fraud-detection\/*/.test(pathname)}
                  to="/app/fraud-detection"
                >
                  <WarningIcon className="link-icon" /> <span>{t('Fraud Detection')}</span>
                </NavLink>
              </Feature>
              <Feature name="widget-display">
                <ReloadNavLink
                  activeClassName={activeClName}
                  className="menu-link"
                  to="/widget-display/footer"
                >
                  <WidgetsIcon className="link-icon" /> <span>{t('Widgets')}</span>
                </ReloadNavLink>
              </Feature>

              {!profileDetail.whitelabel && (
              <>
                <Feature name="widget-display">
                  <ReloadNavLink
                    activeClassName={activeClName}
                    className="menu-link"
                    target="_blank"
                    to="https://www.optty.com/demo-video"
                  >
                    <DemoVideoIcon className="link-icon" /> <span>{t('Watch Demo Video')}</span>
                  </ReloadNavLink>
                </Feature>
                <Feature name="widget-display">
                  <ReloadNavLink
                    activeClassName={activeClName}
                    className="menu-link"
                    target="_blank"
                    to="https://www.optty.com/demo-video"
                  >
                    <TrainingVideoIcon className="link-icon" /> <span>{t('UPP Training Video')}</span>
                  </ReloadNavLink>
                </Feature>
              </>
              )}

              <Feature name="rules-and-configuration">
                <ReloadNavLink
                  activeClassName={activeClName}
                  className="menu-link"
                  exact
                  to="/app/rules-and-configuration"
                >
                  <SettingsIcon className="link-icon" />{' '}
                  <span>{t('Rules and Configuration')}</span>
                </ReloadNavLink>
              </Feature>
              <Feature name="website-settings">
                <ReloadNavLink
                  activeClassName={activeClName}
                  className="menu-link"
                  exact
                  to="/app/webhook-settings"
                >
                  <SettingsIcon className="link-icon" />{' '}
                  <span>{t('Admin Settings')}</span>
                </ReloadNavLink>
              </Feature>
              <Feature name="optty-direct-menu">
                <List
                  component="nav"
                  className="nav-list"
                >
                  <NavLink
                    className="nav-report m-link"
                    isActive={() => ['/app/optty-direct/get-paid', '/app/optty-direct/quick-link', '/app/optty-direct/configuration'].includes(pathname)}
                    to="/app/optty-direct/get-paid"
                  >
                    <ListItem
                      button
                      className="main-menu"
                      onClick={handleOpttyDirectMenuClick}
                    >
                      <ListItemIcon className="optty-direct-icon">
                        <PaymentTypeIcon
                          style={{ fill: 'white' }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        className="optty-direct-text"
                      >{t('Optty Direct')}
                      </ListItemText>
                      {openOpttyDirectMenu ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse
                      in={openOpttyDirectMenu}
                      timeout="auto"
                      unmountOnExit
                      className="collapse-option"
                    >
                      <List
                        className="dropdown-nav"
                        disablePadding
                      >
                        <NavLink
                          className="nav-report m-link"
                          isActive={() => ['/app/optty-direct/get-paid'].includes(pathname)}
                          to="/app/optty-direct/get-paid"
                        >
                          <ListItem
                            button
                            className="nested"
                          >
                            <ListItemIcon>
                              <FiberManualRecordIcon
                                className="optty-direct-dropdown-icon"
                              />
                            </ListItemIcon>
                            <ListItemText
                              className="optty-direct-text"
                            >{t('Get Paid')}
                            </ListItemText>
                          </ListItem>
                        </NavLink>
                        <Feature name="quick-link-tab">
                          <NavLink
                            className="nav-report m-link"
                            isActive={() => ['/app/optty-direct/quick-link'].includes(pathname)}
                            to="/app/optty-direct/quick-link"
                          >
                            <ListItem
                              button
                              className="nested"
                            >
                              <ListItemIcon>
                                <FiberManualRecordIcon
                                  className="optty-direct-dropdown-icon"
                                />
                              </ListItemIcon>
                              <ListItemText
                                className="optty-direct-text"
                              >{t('Quick Link')}
                              </ListItemText>
                            </ListItem>
                          </NavLink>
                        </Feature>
                        <NavLink
                          className="nav-report m-link"
                          isActive={() => ['/app/optty-direct/configuration'].includes(pathname)}
                          to="/app/optty-direct/configuration"
                        >
                          <ListItem
                            button
                            className="nested"
                          >
                            <ListItemIcon>
                              <FiberManualRecordIcon
                                className="optty-direct-dropdown-icon"
                              />
                            </ListItemIcon>
                            <ListItemText
                              className="optty-direct-text"
                            >{t('Configuration')}
                            </ListItemText>
                          </ListItem>
                        </NavLink>
                      </List>
                    </Collapse>
                  </NavLink>
                </List>
              </Feature>
            </div>
          )}
        </div>
      </div>
    );
  }
  return Object.keys(profileDetail).length && content;
};

export default SideBar;
