/* eslint-disable no-useless-escape, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events, no-restricted-globals, camelcase */
import React, {
  useEffect, useRef, useState,
} from 'react';

import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { apiAuth } from 'libs/utils/api';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { notifyError, notifyOk as notify } from 'libs/utils/Toaster';
import { Editor } from '@tinymce/tinymce-react';
import { SketchPicker } from 'react-color';
import { colorConfigType } from 'libs/utils';
import Modal from 'libs/utils/Modal';
import {
  ArrowRight,
  ColorLens,
} from '@material-ui/icons';
import { InputLabel } from '@material-ui/core';
import debounce from 'libs/debounce';
import {
  Button,
  FormControlBox,
  SingleLineTextInput,
} from './FormElements';
import './MerchantRules.css';
import { ButtonDisplay, useButton } from './FormElements/Button';
import { InputStyling } from './FormElements/SingleLineTextInput';
import styles from './styles/ConfigureCheckout.module.css';
import ManageMerchantPage from '../routes/ManageMerchant';
import {
  ButtonBgColor,
  ButtonText,
  ButtonTextColor,
  ButtonPosition,
  ButtonSize, editorConfig,
} from '../constants/index';
import ConditionalWrapper from '../libs/utils/conditionalWrapper';
import { MerchantConfigurationOptions } from '../@types/config';
import { TINYMCE_API_KEY } from '../config';
import Panel from './Panel/Panel';

export enum FeesValue {
  yes = 'enabled',
  no = 'disabled',
  custom = 'custom',
}

interface IFormState {
  errorMessage?: string;
  success: boolean;
  checkoutStyling: string;
  termsLink?: string;
  iframeUrl?: string;
  termsLinkText?: string;
  buttonText?: string;
  buttonPosition?: string;
  buttonSize?: string;
  buttonTextColor?: string;
  buttonBackgroundColor?: string;
}

export interface ISelected {
  providerName?: string | null;
  currency?: string | null;
  imageUrl?: string | null;
  recordId?: string | null;
  isActive?: boolean | null;
  apiKey?: string | null;
  provider?: string | null;
  minAmount?: number | null
}

export interface IInstallment {
  rate: {
    amount: number;
    currency: string;
  };
  termDuration: number;
  termUnit: string;
}

const urlPattern = /^(http|https):\/\/[^ "]+$/;

interface IConfigureCheckoutWidgetForm {
  recordId?: number;
  /*
   * true if you are modifying the configuration of an existing provider
   * false if modifying the configuration of a provider being created
   */
  isExistingProvider?: boolean;
  selectedBnpl?: ISelected;
  handleNext: any;
  isNewBnpl?: boolean;
}

const ConfigureCheckoutWidgetForm: React.FC<IConfigureCheckoutWidgetForm> = ({
  recordId: propRecordId, selectedBnpl: propsSelectedBnpl, isExistingProvider = true, handleNext, isNewBnpl,
}) => {
  const [selectedBnpl, setSelectedBnpl] = useState<ISelected>(propsSelectedBnpl ?? {});
  const [bnplInstallment, setBnplInstallment] = useState<IInstallment>();
  let { providerName } = useParams<{ providerName: string }>();
  let { currency }: any = useParams();
  const { setButtonState } = useButton();
  const { t } = useTranslation();
  const [formState, setFormState] = useState<IFormState>({
    errorMessage: '',
    success: false,
    buttonTextColor: '#ffffff',
    checkoutStyling: '',
    buttonText: '',
    buttonPosition: '',
    buttonSize: '',
    buttonBackgroundColor: '#000000',
    iframeUrl: '',
    termsLink: 'none',
    termsLinkText: '',
  });
  const [recordID, setRecordID] = useState<number>();
  const [colorConfig, setColorConfig] = useState('');
  const [colorPickerContainerDisplay, setColorPickerContainerDisplay] = useState('none');
  const [colorTracker, setColorTracker] = useState('#000000');
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [checkoutButtonState, setCheckoutButtonState] = useState({
    buttonText: ButtonText,
    buttonPosition: ButtonPosition,
    buttonSize: ButtonSize,
    buttonBackgroundColor: ButtonBgColor,
    buttonTextColor: ButtonTextColor,
    fetchedGlobal: false,
  });

  const previewModal = () => {
    setShowPreviewModal(!showPreviewModal);
  };

  const [buttonTextLength, setButtonTextLength] = useState(0);
  const [editorHTML, setEditorHTML] = useState<string>('');
  const [termsEditorHTML, setTermsEditorHTML] = useState<string>('');

  const editorRef = useRef(null);
  const termsEditorRef = useRef(null);

  // Used to ensure useEffects only run after the [] dependency useEffect is run
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) return;
    const reload = new CustomEvent('component-render', {
      detail: {
        version: 'v2',
        value: {
          ...formState,
          editorState: editorHTML,
          termsEditorState: termsEditorHTML,
          selectedBnpl,
          bnplInstallment,
        },
      },
    });
    window.dispatchEvent(reload);
  }, [showPreviewModal]);

  useEffect(() => {
    if (firstRender.current) return;
    if (!isExistingProvider) {
      const reload = new CustomEvent('component-render', {
        detail: {
          version: 'v2',
          value: {
            ...formState,
            editorState: editorHTML,
          },
        },
      });
      window.dispatchEvent(reload);
    }
  }, [isExistingProvider]);

  useEffect(() => {
    if (firstRender.current) return;
    postMessageToWidgetWindow({
      version: 'v2',
      value: {
        ...formState,
        editorState: editorHTML,
        termsEditorState: termsEditorHTML,
        selectedBnpl,
        bnplInstallment,
      },
    });
  }, [formState, editorHTML, termsEditorHTML, bnplInstallment]);

  /*
   * Use global or custom styling for form depending on which is chosen
   * Only formState.checkoutStyling is necessary as using formState as the dependency
   * will result in an infinite update loop
   */
  useEffect(() => {
    if (firstRender.current) return;
    setFormState((prevState) => ({
      ...prevState,
      buttonTextColor: formState.checkoutStyling === 'global' ? checkoutButtonState.buttonTextColor.global : checkoutButtonState.buttonTextColor.custom,
      buttonText: formState.checkoutStyling === 'global' ? checkoutButtonState.buttonText.global : checkoutButtonState.buttonText.custom,
      buttonPosition: formState.checkoutStyling === 'global' ? checkoutButtonState.buttonPosition.global : checkoutButtonState.buttonPosition.custom,
      buttonSize: formState.checkoutStyling === 'global' ? checkoutButtonState.buttonSize.global : checkoutButtonState.buttonSize.custom,
      buttonBackgroundColor: formState.checkoutStyling === 'global' ? checkoutButtonState.buttonBackgroundColor.global : checkoutButtonState.buttonBackgroundColor.custom,
    }));
  }, [formState.checkoutStyling]);

  /**
   * No state is being set in the two functions below
   * The useEffect after them aggregates their results and sets state in one shot
   */

  // Get global checkout button configuration
  // eslint-disable-next-line consistent-return
  const getGlobalConfig = async () => {
    try {
      const response = await apiAuth.get('/merchant/configuration/checkout');
      const { data } = response;
      const allThemes = data.checkout.themes;
      const filterTheme = allThemes.filter((theme: any) => theme.key === MerchantConfigurationOptions.Theme2);
      const theme2Data = filterTheme[0];
      return theme2Data.properties;
    } catch (error) {
      console.log({ error });
    }
  };

  // Get BNPL configuration
  // eslint-disable-next-line consistent-return
  const getBNPLConfig = async (provName: string, curr: string) => {
    try {
      let response;
      if (curr) {
        response = await apiAuth.get(
          `/merchant/bnplprovider/details/${provName}?currency=${curr}`,
        );
      } else {
        response = await apiAuth.get(`/merchant/bnplprovider/details/${provName}`);
      }
      return response.data;
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        if (selectedBnpl) {
          const curr = propsSelectedBnpl?.currency ?? currency;
          const response = await apiAuth.get(
            `/options/bnplproviders/available?amount=${selectedBnpl.minAmount || 10}&currency=${curr}`,
          );
          const matchedOption = response.data.find((data) => data.providerName === selectedBnpl.providerName && data.currency === curr);
          if (matchedOption) {
            setBnplInstallment(matchedOption.instalment);
          }
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchOptions();
  }, [selectedBnpl, propsSelectedBnpl, currency]);

  // Get data from API and set state variables on first load of component
  useEffect(() => {
    // Initialize widget so postMessage has a place to post message to
    const reload = new CustomEvent('component-render', {
      detail: {
        version: 'v2',
        value: {},
      },
    });
    window.dispatchEvent(reload);

    async function initState() {
      if (firstRender.current) firstRender.current = false;

      if (!isExistingProvider) {
        setFormState((prevState) => ({
          ...prevState,
          checkoutStyling: 'global',
        }));
      }

      const globalConfig = await getGlobalConfig();

      if (!isExistingProvider && !providerName && propsSelectedBnpl?.provider) {
        providerName = propsSelectedBnpl.provider;
      }
      if (!isExistingProvider && !currency && propsSelectedBnpl?.currency) {
        currency = propsSelectedBnpl.currency;
      }

      const bnplConfig = await getBNPLConfig(providerName, currency);

      setRecordID(bnplConfig?.recordId);
      setSelectedBnpl(bnplConfig);

      setCheckoutButtonState((prevState) => ({
        ...prevState,
        buttonTextColor: {
          global: globalConfig.buttonTextColor.value !== '' ? globalConfig.buttonTextColor.value : globalConfig.buttonTextColor.default,
          custom: bnplConfig.checkoutConfig.button.color,
        },
        buttonText: {
          global: globalConfig.buttonText.value !== '' ? globalConfig.buttonText.value : globalConfig.buttonText.default,
          custom: bnplConfig.checkoutConfig.button.text,
        },
        buttonPosition: {
          global: globalConfig.buttonPosition.value !== '' ? globalConfig.buttonPosition.value : globalConfig.buttonPosition.default,
          custom: bnplConfig.checkoutConfig.button.position,
        },
        buttonSize: {
          global: globalConfig.buttonSize.value !== '' ? globalConfig.buttonTextColor.value : globalConfig.buttonTextColor.default,
          custom: bnplConfig.checkoutConfig.button.size,
        },
        buttonBackgroundColor: {
          global: globalConfig.buttonBackgroundColor.value !== '' ? globalConfig.buttonBackgroundColor.value : globalConfig.buttonBackgroundColor.default,
          custom: bnplConfig.checkoutConfig.button.background,
        },
      }));

      setFormState((prevState) => ({
        ...prevState,
        checkoutStyling: bnplConfig.checkoutConfig?.button?.type,
        iframeUrl: bnplConfig.checkoutConfig?.iframeUrl || '',
        termsLink: bnplConfig.checkoutConfig?.termsCondition?.type || '',
        termsLinkText: bnplConfig.checkoutConfig?.termsCondition?.linkText || 'Terms \& Conditions',
      }));

      if (bnplConfig.checkoutConfig?.content) {
        setEditorHTML(bnplConfig.checkoutConfig?.content);
      }

      if (bnplConfig.checkoutConfig.termsCondition.type === 'modal') {
        setTermsEditorHTML(bnplConfig.checkoutConfig?.termsCondition.content);
      }
    }
    initState();
  }, []);

  const handleChange = (e: any) => {
    const { id, name, value } = e.target;
    const stateUpdate: Record<string, any> = {
      [name]: value,
    };

    const inputLength = value.length;
    if (id === 'buttonText' && inputLength <= 15) {
      setButtonTextLength(inputLength);
    }

    if (name === 'buttonText' || name === 'buttonPosition' || name === 'buttonSize') {
      setCheckoutButtonState((prevState: any) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          custom: value,
        },
      }));
    }
    setFormState((prevState) => ({
      ...prevState,
      ...stateUpdate,
    }));
  };

  const contentHasIframe = (content: string) => {
    if (!content) {
      return false;
    }
    if (content?.includes('%iframe')) {
      return true;
    }
    return false;
  };

  const contentHasTermsConditions = (content: string) => {
    if (!content) {
      return false;
    }
    if (content?.includes('%termsconditions')) {
      return true;
    }
    return false;
  };

  const validTermsLink = () => {
    if (formState.termsLink !== 'link' || (formState.termsLink === 'link' || '')) {
      return true;
    }
    return false;
  };

  const hasTermsConditions = () => {
    if (!(formState.termsLink === '' && contentHasTermsConditions(editorHTML))) {
      return true;
    }
    return false;
  };

  const isFormValid = () => {
    return (
      (formState.checkoutStyling !== 'custom'
        || ((formState.buttonTextColor !== '' && (formState?.buttonTextColor?.length === 7 || formState?.buttonTextColor?.length === 4))
          && (formState.buttonText !== '')
          && (formState.buttonPosition !== '')
          && (formState.buttonSize !== '')
          && (formState.buttonBackgroundColor !== '')))
      && (!(formState.iframeUrl === '' && contentHasIframe(editorHTML)))
      && hasTermsConditions()
      && validTermsLink()
    );
  };

  const handleAPISubmit = async (e: any) => {
    e.preventDefault();
    if (!isFormValid()) return;
    try {
      setButtonState(true);
      const payload: Record<string, any> = {
        recordId: propRecordId || recordID,
        checkoutConfig: {
          content: editorHTML,
          iframeUrl: formState.iframeUrl,
          button: {
            type: formState.checkoutStyling,
            text: formState.buttonText,
            color: formState.buttonTextColor,
            background: formState.buttonBackgroundColor,
            position: formState.buttonPosition,
            size: formState.buttonSize,
          },
          termsCondition: {
            type: formState.termsLink,
            content: formState.termsLink === 'modal' ? termsEditorHTML : '',
            linkText: formState.termsLinkText,
          },
        },
      };
      const response = await apiAuth.put('/merchant/bnplprovider/update', payload);
      notify('Rules updated for provider successfully');
      setFormState((prevState) => ({
        ...prevState,
        success: true,
      }));

      setButtonState(false);

      if (!isExistingProvider) {
        handleNext(response.data.id);
      }
    } catch (error) {
      setButtonState(false);
      notifyError('Error updating rules for provider');
      setFormState((prevState) => ({
        ...prevState,
        errorMessage: 'Unable to update rules for provider',
      }));
    }
  };

  const handleColorPickerClickEvent = (fieldName: string) => {
    setColorConfig(fieldName);
    setColorPickerContainerDisplay('block');
  };

  const updateFormState = (key: string, value: any) => {
    setFormState((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const updateCheckoutButtonState = (key: string, value: any) => {
    setCheckoutButtonState((prevState: any) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        custom: value,
      },
    }));
  };

  const postMessageToWidgetWindow = debounce((message: any) => {
    const frames = Array.from(window.frames);
    frames.forEach((item) => {
      item.postMessage(message, item.origin);
    });
  }, 1000);

  const ColorPicker = (props: { colorConfig: string }) => {
    return (
      <article
        className="colorPickerContainer"
        style={{ display: colorPickerContainerDisplay }}
      >
        <div className="colorPicker">
          <div className="colorPickerHeader">{colorConfigType(props.colorConfig)}</div>
          <SketchPicker
            className="sketchPicker"
            color={colorTracker}
            disableAlpha
            onChangeComplete={(color) => {
              setColorTracker(color.hex);
            }}
            width="80%"
          />

          <div className={styles.colorPickerFooter}>
            <Button
              className="btn-next"
              onClick={() => setColorPickerContainerDisplay('none')}
              text="Cancel"
              type="button"
            />
            <Button
              className="btn-next"
              onClick={() => {
                updateFormState(props.colorConfig, colorTracker.toUpperCase());
                updateCheckoutButtonState(props.colorConfig, colorTracker.toUpperCase());
                setColorPickerContainerDisplay('none');
              }}
              text="Apply"
              type="button"
            />
          </div>
        </div>
      </article>
    );
  };

  return selectedBnpl ? (
    <ConditionalWrapper
      condition={!isNewBnpl}
      wrapper={(children: React.ReactNode) => <ManageMerchantPage>{children}</ManageMerchantPage>}
    >
      <>
        <div className="shadowed-container">
          <div className="form-title">3. {t('Checkout Widget Configuration ')}</div>
          <div className={styles.gridContainer}>
            <Panel title="Edit Widget Style">
              <div className={styles.formTitle}>Text and Layout</div>
              <div className={styles.titleBorder} />
              <div className={styles.subTitle}>
                Edit the default text and layout for this payment method checkout widget by adding
                in custom content and using the below tags for dynamic, data and statistics.
              </div>
              <div className={styles.editorTitle}>
                <div>
                  <span className={styles.editorTitleBold}>
                    Dynamic Tags
                  </span>
                  <span className={styles.editorTitleFaded}>
                    Star Rating:
                  </span>
                  <span className={styles.editorTitleTag}>%star</span>
                  <button
                    className={styles.editorTitleLink}
                    onClick={() => setShowTagsModal(true)}
                    type="button"
                  >
                    View More Tags
                  </button>
                </div>
                <div>
                  <span className={styles.editorLearnMoreCircle}>?</span>
                  <span className={styles.editorLearnMoreText}>Learn more</span>

                </div>
              </div>
              <Editor
                apiKey={TINYMCE_API_KEY}
                init={editorConfig}
                onEditorChange={() => setEditorHTML(editorRef.current.getContent())}
                onInit={(evt, editor) => {
                  editorRef.current = editor;
                }}
                value={editorHTML}
              />
              <div className={styles.editorTitle}>
                <div>
                  <span className={styles.editorTitleBold}>
                    iFrame URL
                  </span>
                  <Tooltip
                    placement="right"
                    title="Enter an external URL that contains iFrame content. You can then use the %iframe dynamic tag in the HTML editor and the iFrame content will be shown within the checkout widget."
                  >
                    <div className="tooltip">i</div>
                  </Tooltip>
                </div>
                <div>
                  <span className={styles.editorTitleFaded}>
                    Dynamic Tag:
                  </span>
                  <span className={styles.editorTitleTag}>%iframe</span>
                </div>
              </div>
              <SingleLineTextInput
                hasError={false}
                hasSuccess={false}
                id="iframeUrl"
                name="iframeUrl"
                onChange={(e) => handleChange(e)}
                placeholder="Enter URL"
                styling={InputStyling.Full}
                tabIndex={0}
                type="text"
                value={formState.iframeUrl || ''}
              />
              {editorHTML.indexOf('%iframe') !== -1 && !urlPattern.test(formState?.iframeUrl || '') && <small className="text-danger">IFrame URL is not valid</small>}
              <div className={`${styles.paneContainerTitle} ${styles.titleMargin}`}>Checkout Button</div>
              <div className={styles.titleBorder} />
              <RadioGroup
                aria-label="checkoutStyling"
                name="checkoutStyling"
                onChange={handleChange}
                row
                value={formState.checkoutStyling}
              >
                <FormControlLabel
                  control={<Radio color="default" />}
                  label={`Global Styling ${formState.checkoutStyling === 'global' ? ' (seen in preview)' : ''}`}
                  value="global"
                />
                <FormControlLabel
                  control={<Radio color="default" />}
                  label={`Custom Styling ${formState.checkoutStyling === 'custom' ? ' (seen in preview)' : ''}`}
                  value="custom"
                />
              </RadioGroup>
              {
                formState.checkoutStyling === 'custom' && (
                  <>
                    <ColorPicker colorConfig={colorConfig} />
                    <FormControlBox
                      className="text-bolder"
                      controlClassName=""
                      htmlFor="buttonText"
                      labelText={t('Button Text')}
                    >
                      <SingleLineTextInput
                        hasError={false}
                        hasSuccess={false}
                        id="buttonText"
                        maxLength={15}
                        name="buttonText"
                        onChange={(e) => handleChange(e)}
                        placeholder="Checkout"
                        styling={InputStyling.Full}
                        tabIndex={0}
                        type="text"
                        value={checkoutButtonState.buttonText.custom || ''}
                      />
                      {buttonTextLength > 0 && <small style={{ color: 'red', marginTop: '7px' }}>{t('Character Count')}: {buttonTextLength}</small>}
                      {formState.buttonText === '' && <small className="text-danger">Button text is required</small>}
                    </FormControlBox>
                    <div className={styles.formRow}>
                      <FormControlBox
                        className={`${styles.formControl} text-bolder`}
                        controlClassName=""
                        htmlFor="buttonPosition"
                        labelText={t('Button Position')}
                      >
                        <select
                          className="rules-merchant-form-select"
                          name="buttonPosition"
                          onChange={handleChange}
                          value={checkoutButtonState.buttonPosition.custom}
                        >
                          <option value="left">Left</option>
                          <option value="right">Right</option>
                          <option value="center">Center</option>
                        </select>
                      </FormControlBox>
                      <FormControlBox
                        className="text-bolder"
                        controlClassName=""
                        htmlFor="buttonSize"
                        labelText={t('Button Size')}
                      >
                        <select
                          className="rules-merchant-form-select"
                          name="buttonSize"
                          onChange={handleChange}
                          value={checkoutButtonState.buttonSize.custom}
                        >
                          <option value="small">Small</option>
                          <option value="medium">Medium</option>
                          <option value="large">Large</option>
                        </select>
                      </FormControlBox>
                    </div>

                    <div className={`${styles.formRow}`}>
                      <FormControlBox
                        className={`${styles.formControl} text-bolder`}
                        controlClassName=""
                        htmlFor="buttonTextColor"
                        labelText={t('Text Color')}
                      >
                        <div
                          className={styles.colorControl}
                          onClick={() => handleColorPickerClickEvent('buttonTextColor')}
                          role="button"
                          tabIndex={0}
                        >

                          <div className={styles.parentFlex}>
                            <div
                              className={styles.colorBox}
                              style={{ backgroundColor: formState.buttonTextColor }}
                            >&nbsp;
                            </div>
                            <span className={styles.colorText}>{formState.buttonTextColor}</span>
                          </div>
                          <ColorLens className={styles.colorLens} />
                        </div>
                      </FormControlBox>
                      <FormControlBox
                        className="text-bolder"
                        controlClassName=""
                        htmlFor="buttonBackgroundColor"
                        labelText={t('Background Color')}
                      >
                        <div
                          className={styles.colorControl}
                          onClick={() => handleColorPickerClickEvent('buttonBackgroundColor')}
                          role="button"
                          tabIndex={0}
                        >

                          <div className={styles.parentFlex}>
                            <div
                              className={styles.colorBox}
                              style={{ backgroundColor: formState.buttonBackgroundColor }}
                            >&nbsp;
                            </div>
                            <span className={styles.colorText}>{formState.buttonBackgroundColor}</span>
                          </div>
                          <ColorLens className={styles.colorLens} />
                        </div>
                      </FormControlBox>
                    </div>

                  </>
                )
              }

              <div className={`${styles.paneContainerTitle} ${styles.titleMargin}`}>Terms &amp; Conditions Link</div>
              <div className={styles.titleBorder} />
              <RadioGroup
                aria-label="termsLink"
                name="termsLink"
                onChange={handleChange}
                row
                value={formState.termsLink}
              >
                <FormControlLabel
                  control={<Radio color="default" />}
                  label="No Link"
                  value="none"
                />
                <FormControlLabel
                  control={<Radio color="default" />}
                  label="Text &amp; Open Modal"
                  value="modal"
                />
              </RadioGroup>
              {
                formState.termsLink === 'modal' && (
                  <>
                    <InputLabel htmlFor="termsLinkText">
                      <span className={styles.editorTitleBold}>
                        Terms &amp; Condtion URL text
                      </span>
                      <Tooltip
                        placement="right"
                        title="Enter a descriptive text for BNPL checkout terms and conditions"
                      >
                        <div className="tooltip">i</div>
                      </Tooltip>
                    </InputLabel>
                    <SingleLineTextInput
                      hasError={false}
                      hasSuccess={false}
                      id="termsLinkText"
                      name="termsLinkText"
                      onChange={(e) => handleChange(e)}
                      placeholder="Enter terms &amp; conditions URL text"
                      styling={InputStyling.Full}
                      tabIndex={0}
                      type="text"
                      value={formState.termsLinkText || ''}
                    />
                    {formState.termsLinkText === '' && <small className="text-danger">Terms link text is required</small>}

                    <Editor
                      apiKey={TINYMCE_API_KEY}
                      init={editorConfig}
                      onEditorChange={() => setTermsEditorHTML(termsEditorRef.current.getContent())}
                      onInit={(evt, editor) => {
                        termsEditorRef.current = editor;
                      }}
                      value={termsEditorHTML}
                    />
                  </>
                )
              }
            </Panel>

            <Panel
              action={
                (
                  <div
                    className={styles.paneHeaderPreview}
                    onClick={previewModal}
                  >
                    <div className={styles.previewTitle}>Preview</div>
                    <VisibilityIcon />
                  </div>
                )
              }
              title="Widget Preview"
            >
              <div className={styles.preWidgetContainer}>
                <div className={styles.circle} />
                {selectedBnpl?.imageUrl && (
                  <img
                    alt="Selected BNPL"
                    className={styles.preWidgetLogo}
                    src={selectedBnpl?.imageUrl}
                    width="auto"
                  />
                )}
                <KeyboardArrowUpIcon className={styles.preWidgetIcon} />
              </div>
              <div className={styles.paneContainer}>
                <div
                  className="checkout-box-widget-bnpl"
                />
                <div className="checkout-box-widget-footer-bnpl" />
              </div>

              <div className={styles.actionButton}>
                <Button
                  display={ButtonDisplay.NextButton}
                  onClick={handleAPISubmit}
                  text={(
                    <>
                      {t('Save & Continue')} <ArrowRight />
                    </>
                  )}
                  type="submit"
                />
                {!isExistingProvider && (
                  <Button
                    display={ButtonDisplay.Transparent}
                    onClick={() => handleNext(propRecordId)}
                    text={t('Skip')}
                    type="button"
                  />
                )}
              </div>
            </Panel>
          </div>

          <Modal
            data-testid="preview-modal"
            hide={() => previewModal()}
            isModalShowing={showPreviewModal}
            modalWidth={644}
            showClose
          >
            <div className={styles.paneContainer}>
              <div className={styles.preWidgetContainer}>
                <div className={styles.circle} />
                {selectedBnpl?.imageUrl && (
                  <img
                    alt="Selected BNPL"
                    className={styles.preWidgetLogo}
                    src={selectedBnpl?.imageUrl}
                    width="auto"
                  />
                )}
                <KeyboardArrowUpIcon className={styles.preWidgetIcon} />
              </div>
              <div
                className="checkout-box-widget-bnpl"
              />
              <div className="checkout-box-widget-footer-bnpl" />
            </div>
          </Modal>
          <Modal
            data-testid="tags-modal"
            hide={() => setShowTagsModal(false)}
            isModalShowing={showTagsModal}
            modalWidth={444}
            showClose
          >
            <div className={styles.tagsModalContainer}>
              <div className={styles.tagsModalTitle}>Dynamic Tags</div>
              <div className={styles.tagsModalBody}>
                <div className={styles.tableHeaderGrid}>
                  <div>Tag</div>
                  <div>Example Preview</div>
                </div>
                <div className={styles.tableBodyGrid}>
                  <div className={styles.tableRow}>
                    <div>
                      <span className={styles.tableLabel}>Star Rating: </span>
                      <span className={styles.tableTag}>%star </span>
                    </div>
                    <div className={styles.tableValue}>
                      <div>
                        <img
                          alt="Star rating"
                          className={styles.bnplStar}
                          src="/full-star.png"
                        />
                        <img
                          alt="Star rating"
                          className={styles.bnplStar}
                          src="/full-star.png"
                        />
                        <img
                          alt="Star rating"
                          className={styles.bnplStar}
                          src="/full-star.png"
                        />
                        <img
                          alt="Star rating"
                          className={styles.bnplStar}
                          src="/full-star.png"
                        />
                        <img
                          alt="Star rating"
                          className={styles.bnplStar}
                          src="/empty-star.png"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.tableRow}>
                    <div>
                      <span className={styles.tableLabel}>Interest Rate: </span>
                      <span className={styles.tableTag}>%interest </span>
                    </div>
                    <div className={styles.tableValue}>0%</div>
                  </div>
                  <div className={styles.tableRow}>
                    <div>
                      <span className={styles.tableLabel}>Payment Amount: </span>
                      <span className={styles.tableTag}>%amount </span>
                    </div>
                    <div className={styles.tableValue}>£2.00</div>
                  </div>
                  <div className={styles.tableRow}>
                    <div>
                      <span className={styles.tableLabel}>Payment Duration: </span>
                      <span className={styles.tableTag}>%duration </span>
                    </div>
                    <div className={styles.tableValue}> x4 Weekly</div>
                  </div>
                  <div className={styles.tableRow}>
                    <div>
                      <span className={styles.tableLabel}>Late Fees: </span>
                      <span className={styles.tableTag}>%latefees </span>
                    </div>
                    <div className={styles.tableValue}> £1.00</div>
                  </div>
                  <div className={styles.tableRow}>
                    <div>
                      <span className={styles.tableLabel}>Customer Fees: </span>
                      <span className={styles.tableTag}>%customerfees </span>
                    </div>
                    <div className={styles.tableValue}> None</div>
                  </div>
                  <div className={styles.tableRow}>
                    <div>
                      <span className={styles.tableLabel}>iFrame Content: </span>
                      <span className={styles.tableTag}>%iframe </span>
                    </div>
                    <div className={styles.tableValue}> &nbsp;</div>
                  </div>
                  <div className={styles.tableRow}>
                    <div>
                      <span className={styles.tableLabel}>Terms and Conditions: </span>
                      <span className={styles.tableTag}>%termsconditions </span>
                    </div>
                    <div className={styles.tableValue}> &nbsp;</div>
                  </div>
                </div>
              </div>
              <div className={styles.tagsModalFooter}>
                <Button
                  disabled={!isFormValid()}
                  display={ButtonDisplay.NextButton}
                  onClick={() => setShowTagsModal(false)}
                  text="Done"
                  type="button"
                />
              </div>
            </div>
          </Modal>
        </div>
      </>
    </ConditionalWrapper>
  ) : null;
};

export default ConfigureCheckoutWidgetForm;
