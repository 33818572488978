// MultiSelect.tsx
import React, { useState } from 'react';
import './MultiSelect.css';

interface IOption {
  value: string;
  label: string;
}

interface IMultiSelectProps {
  options: IOption[];
  selectedOptions: IOption[];
  onChange: (selectedOptions: IOption[]) => void;
}

const MultiSelect: React.FC<IMultiSelectProps> = ({ options, selectedOptions, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (option: IOption) => {
    const isSelected = selectedOptions.some((o) => o.value === option.value);
    const newSelectedOptions = isSelected
      ? selectedOptions.filter((o) => o.value !== option.value)
      : [...selectedOptions, option];

    onChange(newSelectedOptions);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="multi-select">
      <div
        className="multi-select-header"
        onClick={toggleDropdown}
        role="button"
        tabIndex={0}
        onKeyPress={(e) => {
          if (e.key === 'Enter') toggleDropdown();
        }}
      >
        <span>{selectedOptions.length > 0 ? selectedOptions.map((o) => o.label).join(', ') : 'Select options'}</span>
        <button
          className="toggle-button"
          type="button"
        >{isOpen ? '▲' : '▼'}
        </button>
      </div>
      {isOpen && (
        <div className="multi-select-options">
          {options.map((option) => (
            <div
              key={option.value}
              className={`option ${selectedOptions.some((o) => o.value === option.value) ? 'selected' : ''}`}
              onClick={() => handleOptionClick(option)}
              role="option"
              aria-selected="false"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter') handleOptionClick(option);
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
