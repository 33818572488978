export enum MerchantConfigurationOptions {
  MerchantFont = 'merchantFont',
  MerchantColor = 'merchantColor',
  Logo = 'logo',
  WidgetsVisibility = 'widgetsVisibility',
  Texts = 'texts',
  Everything = 'everything',
  Theme1 = 'theme1',
  Theme2 = 'theme2'
}

export enum FieldTypes {
  Text = 'text',
  Select = 'select',
  Color = 'color'
}
