import React from 'react';
import styles from './Panel.module.css';

interface IPanel {
  title: string | Element;
  action?: string | React.ReactElement;
  contentClassName?: string;
}

const Panel:React.FC<IPanel> = ({
 title, action, children, contentClassName = '',
}) => {
  return (
    <div className={`${styles.panel}`}>
      <div className={styles.header}>
        <span>{title}</span>
        <span>{action}</span>
      </div>
      <div className={`${styles.content} ${contentClassName}`}>
        {children}
      </div>
    </div>
  );
};

export default Panel;
