import React, { useEffect, useMemo, useState } from 'react';
import { composeClasses } from 'libs/utils';
import { Box, Typography, Button as MaterialButton } from '@material-ui/core';

import './MerchantOAuthConfigs.css';
import { CheckCircle } from '@material-ui/icons';
import Modal from 'libs/utils/Modal';
import { apiAuth } from 'libs/utils/api';
import { strippedBnplName } from 'libs/utils/bnpl-exceptions';
import { notifyError, notifyOk } from 'libs/utils/Toaster';
import { useTranslation } from 'react-i18next';
import { Button } from './FormElements';
import { ButtonDisplay } from './FormElements/Button';
import OpttyScreenLoader from './layouts/Loader/OpttyScreenLoader';

interface IMerchantOAuthConfigsProps {
  providerName: string;
  currency: string;
  selectedBnpl: any
}

interface IConfigProvider {
  authorizeUrl: string;
  clientId: string;
  clientSecret: string;
}

export const MerchantOAuthConfigs = ({
  providerName,
  currency,
  selectedBnpl,
}: IMerchantOAuthConfigsProps) => {
  const [isModalShowing, setIsModalShowing] = useState<boolean>(false);
  const [configProviderDetails, setConfigProviderDetails] = useState<IConfigProvider>();
  const [isOAuthConfigured, setOAuthConfigured] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const MESSAGE = {
    connectedMessage: t('Your are now connected to'),
    confirmMessage: t('Are you sure you want to'),
  };

  useEffect(() => {
    if (providerName && providerName.length) {
      getConfigProviderDetails(providerName);
    }
  }, [providerName]);

  const getConfigProviderDetails = async (providerName: string) => {
    try {
      const URL = `/oauth/configs?provider=${providerName}&currency=${currency}`;
      await apiAuth.get(URL).then((result) => {
        if (result.status === 200) {
          const response = result.data;
          setConfigProviderDetails(response);
        }
      });
    } catch (error) {
      console.error('error while getting response', error);
    }
  };

  const handleConnect = () => {
    setLoading(true);
    const paymentAuthorizeURL = configProviderDetails?.authorizeUrl;
    if (paymentAuthorizeURL && paymentAuthorizeURL?.length) {
      window.location.href = paymentAuthorizeURL;
    } else {
      notifyError(`Error in connecting to ${strippedBnplName(providerName)}`);
      setLoading(false);
    }
  };

  const handelToggleModel = (state: boolean) => {
    setIsModalShowing(state);
  };

  const handleCancelActivation = (state: boolean) => {
    setIsModalShowing(state);
  };

  const handleDisconnectAccount = async () => {
    await apiAuth.post('/merchant/bnplprovider/toggle/state', {
      bnplProvider: providerName,
      bnplCurrency: currency,
    })
      .then((response) => {
        if (response.status === 201) {
          setOAuthConfigured(!isOAuthConfigured);
          notifyOk(('The provider has been successfully Disconnected'));
          setIsModalShowing(false);
        } else {
          throw new Error('An error occured');
        }
      })
      .catch((error) => {
        console.log(error);
        notifyError('Error Disconnecting provider');
        setIsModalShowing(false);
      });
  };

  const merchantOAuthPaymentConnect = () => {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <MaterialButton
          onClick={() => handleConnect()}
          variant="outlined"
          size="medium"
          className="OAuthPaymentConnect"
          endIcon={(
            <img
              src={selectedBnpl.imageUrl as string}
              width={100}
              alt="bnpl-url"
              height={25}
            />
          )}
        >
          <Typography variant="body2">
            {t('Connect With')}
          </Typography>
        </MaterialButton>
      </Box>
    );
  };

  const merchantOAuthPaymentDisconnect = () => {
    return (
      <>
        <Box
          display="flex"
          justifyContent="center"
          mb={2}
        >
          <CheckCircle className="verify-circle" />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          mb={2}
        >
          <Typography variant="body2">
            {MESSAGE.connectedMessage} <b>{strippedBnplName(providerName)}</b>
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
        >
          <MaterialButton
            variant="outlined"
            size="small"
            className="OAuthPaymentDisconnect"
            onClick={() => {
              handelToggleModel(true);
            }}
          >
            Disconnect
          </MaterialButton>
        </Box>
      </>
    );
  };

  const renderConfirmationDialog = useMemo(() => {
    return (
      <Modal
        data-testid="activation-modal"
        hide={isModalShowing}
        isModalShowing={isModalShowing}
        modalWidth={444}
      >
        <div className="merchantModalTitle">
          Disconnect Account
        </div>
        <Typography
          variant="body2"
          className="confirmMSG"
        >
          {MESSAGE.confirmMessage} disconnect <b>{strippedBnplName(providerName)}</b>?
        </Typography>
        <div className="merchantModalButtons">
          <Button
            display={ButtonDisplay.InvertedNext}
            onClick={() => handleCancelActivation(false)}
            text="Cancel"
            type="button"
          />
          <Button
            display={ButtonDisplay.Danger}
            onClick={() => handleDisconnectAccount()}
            text="Disconnect"
            type="button"
          />
        </div>
      </Modal>
    );
  }, [isModalShowing]);

  return (
    <div className="content-container">
      <div
        className={composeClasses('shadowed-container', 'credential-container')}
        data-testid="manage-merchant-config"
        id={selectedBnpl.isOAuthActive && isOAuthConfigured ? 'merchantOAuthDisconfigPayment' : 'merchantOAuthConfigPayment'}
      >
        {selectedBnpl.isOAuthActive && isOAuthConfigured ? merchantOAuthPaymentDisconnect() : merchantOAuthPaymentConnect()}
        {renderConfirmationDialog}
      </div>
      {
        loading && <OpttyScreenLoader isLoading />
      }
    </div>
  );
};
