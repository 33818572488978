import { API_KEY, API_SECRET, IProviderInputInterface } from './payment-provider-types';

export const voltConfigs: IProviderInputInterface[] = [
    {
      name: 'username',
      label: 'Username',
      type: 'text',
      keyType: API_KEY,
      isRequired: true,
    },
    {
      name: 'password',
      label: 'Password',
      type: 'password',
      keyType: API_SECRET,
      isRequired: true,
    },
    {
      name: 'publicKeyId',
      label: 'Public Key ID',
      type: 'text',
      keyType: API_KEY,
      isRequired: false,
    },
    {
      name: 'privateKey',
      label: 'Private Key',
      type: 'password',
      keyType: API_SECRET,
      isRequired: false,
    },
  ];

  export const visaCardConfigs = [
    {
      name: 'merchantId',
      label: 'Merchant ID',
      type: 'text',
      keyType: API_KEY,
    },
    {
      name: 'apiKey',
      label: 'Key',
      type: 'text',
      keyType: API_KEY,
    },
    {
      name: 'sharedSecret',
      label: 'Shared Secret',
      type: 'password',
      keyType: API_SECRET,
    },
  ];