import React from 'react';
import { useRouteMatch } from 'react-router-dom';

interface IReloadNavLinkInterface {
  children: any;
  to: string;
  className?: string;
  style?: any;
  activeClassName?: string;
  activeStyle?: any;
  exact?: boolean;
  strict?: boolean;
  onClick?: any;
  target?: string;
}

const ReloadNavLink = ({
  children,
  to = '',
  className = '',
  style = null,
  activeClassName = '',
  activeStyle = null,
  exact = false,
  strict = false,
  onClick,
  target = '',
}: IReloadNavLinkInterface) => {
  const active = useRouteMatch({ path: to, exact, strict });
  let styleObj = style;
  if (active) {
    styleObj = { ...styleObj, ...activeStyle };
  }
  return (
    <a
      className={className + (active ? ` ${activeClassName}` : '')}
      href={to}
      onClick={onClick}
      style={styleObj}
      target={target}
    >
      {children}
    </a>
  );
};

export default ReloadNavLink;
