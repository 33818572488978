/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useState } from 'react';
import './SelectDropDown.css';
import SingleLineTextInput, { CursorStyling, InputStyling } from '../SingleLineTextInput';

interface IOption {
  value: string;
  title: string;
  subTitle: string;
}

interface IOpttySelectDropDownWrapper
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  options: IOption[];
  placeholder?: string;
  hasSuccess?: boolean;
  hasError?: boolean;
  callback: Function;
}
const SelectDropDown: FC<IOpttySelectDropDownWrapper> = ({
  options,
  placeholder = '',
  hasSuccess = false,
  hasError = false,
  callback,
}) => {
  const [dropDown, setDropDown] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');

  const handleClickEvent = (item: IOption) => {
    // Set selected value in state
    setSelectedValue(item.title);
    // return value to parent component
    callback(item.value);
    // toggle dropdown off
    setDropDown(false);
  };

  return (
    <div>
      <SingleLineTextInput
        cursor={CursorStyling.Dropdown}
        data-testid="permissions-dropdown"
        dropDownDirection={dropDown ? 'upward' : 'downward'}
        hasError={hasError}
        hasSuccess={hasSuccess}
        id="permissions"
        name="permissions"
        onClick={() => setDropDown(!dropDown)}
        placeholder={placeholder || 'Select Permission'}
        required
        styling={InputStyling.Full}
        type="button"
        useAsDropDown
        value={selectedValue || placeholder}
      />
      {dropDown && (
        <ul className="container">
          {options
            && options.length
            && options.map((item) => (
              <li
                className="list-container"
                data-testid="data-list-container"
                key={item.subTitle}
                onClick={() => handleClickEvent(item)}
              >
                <span className="title">{item.title}</span>{' '}
                <span className="subTitle">{item.subTitle}</span>
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};

export default SelectDropDown;
