import React from 'react';
import { authService } from 'services/auth.service';

const HandleRedirect = () => {
  authService.handleAuthentication();

  return <div />;
};

export default HandleRedirect;
