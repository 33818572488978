import React from 'react';
import './SkeletalScreenLoader.css';

const SkeletalScreenLoader = () => {
  return (
    <>
      <main className="blank-main-content">
        <div className="blank-sidebar-wraper">
          <div className="blank-sidebar-content" />
        </div>

        <div className="blank-dashboard-content">
          <article>
            <div>
              <div className="blank-top-header" />
              <div className="blank-top-descrp" />

              <div className="blank-page-wrapper">
                <div className="blank-page-title" />
                <div className="blank-pebble-wrapper">
                  {[...Array(9)].map((e, i) => (
                    <div
                      className={`pebble-${i + 1}`}
                      key={`pebble-${i + 1}`}
                    />
                  ))}
                </div>
                <div className="blank-rectangle-1" />
                <div className="blank-rectangle-2" />
              </div>
            </div>
          </article>
        </div>
      </main>
    </>
  );
};

export default SkeletalScreenLoader;
