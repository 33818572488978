import React from 'react';
import ReactDOM from 'react-dom';
import dotenv from 'dotenv';
import noConsole from 'libs/utils/no-console';
import axe from '@axe-core/react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { initialize } from './services/internationalization.service';

dotenv.config();
initialize();

// noConsole();

if (process.env.NODE_ENV !== 'production') {
  axe(React, ReactDOM, 1000);
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
