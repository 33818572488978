import React, { FC } from 'react';
import { composeClasses } from '../../../libs/utils';
import './FormControlWrapper.css';

interface IOpttyFormControlWrapper
  extends React.DetailedHTMLProps<
    React.LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  > {
  controlClassName?: string;
  align?: 'left' | 'right' | 'center';
  labelText?: string;
  disabled?: boolean;
  isRequired?: boolean;
}
const FormControlWrapper: FC<IOpttyFormControlWrapper> = ({
  align,
  disabled,
  ...props
}) => {
  return (
    <div
      className={composeClasses(props.controlClassName, align)}
      data-has-label={!!props.labelText || 'false'}
    >
      {props.labelText && (
        <label
          className={composeClasses(props.className, disabled ? 'is-disabled' : 'slick')}
          htmlFor={props.htmlFor}
        >
          {props.labelText}
          {
            props.isRequired && <span style={{ color: '#ff0000', verticalAlign: 'super', fontSize: '0.7rem' }}>*</span>
          }
        </label>
      )}
      {props.children}
    </div>
  );
};

export default FormControlWrapper;
