/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
import './FraudConfiguration.css';
import React, { useEffect, useState } from 'react';
import {
 Theme, Typography, createStyles, makeStyles,
} from '@material-ui/core';
import Button, { ButtonDisplay } from 'components/FormElements/Button';
import Modal from 'libs/utils/Modal';
import MultiSelect from 'libs/MultiSelect';

type Props = {
  isPopupOpen: boolean
  onClose: () => void
  onSubmit: (selectedFrauds: string[]) => void,
  onChange: (value: string[]) => void
  availableFrauds: { id: string, label: string }[]
  selected: string[]
  formState: unknown
  selectedBnpl: { fraudDetectors: { id: string, label: string }[] }
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    paper: {
      position: 'absolute',
      width: 559,
      backgroundColor: theme.palette.background.paper,
      border: 'none',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      outline: 0,
    },
  }));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const FraudConfiguration = ({
  isPopupOpen, onClose, onSubmit, onChange, selected, availableFrauds, formState, selectedBnpl,
}: Props) => {
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();
  const [selectedFrauds, setSelectedFrauds] = useState<string[]>(selected);

  useEffect(() => {
    if (selectedBnpl && selectedBnpl.fraudDetectors) {
      const defaultFrauds = selectedBnpl.fraudDetectors.filter((fraudDetector) => {
        const fields: any = formState;
        return fields[fraudDetector.id];
      }).map((fraudDetector) => fraudDetector.label);
      setSelectedFrauds(defaultFrauds);
    }
  }, [isPopupOpen, selectedBnpl, formState]);

  useEffect(() => { setSelectedFrauds(selected); },
  [selected]);

  return (
    <>
      <Modal
        isModalShowing={isPopupOpen}
        hide={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          className={classes.paper}
          style={modalStyle}
        >
          <Typography
            variant="h6"
            className="fraud_label"
          >
            Configure Fraud Detection
          </Typography>
          <div className="fraud_content selectProviderAction">
            <MultiSelect
              options={availableFrauds?.map((fraud) => { return { label: fraud.label, value: fraud.label }; })}
              selectedOptions={selected?.map((selected) => { return { label: selected, value: selected }; })}
              onChange={(value) => {
                const result = value.map((value) => value.label);
                onChange(result);
                setSelectedFrauds(result);
        }}
            />
          </div>
          <div className="merchantModalButtons">
            <Button
              display={ButtonDisplay.InvertedNext}
              onClick={() => {
                onClose();
}}
              text="Cancel"
              type="button"
            />
            <Button
              display={ButtonDisplay.InvertedSuccess}
              onClick={() => onSubmit(selectedFrauds)}
              text="Save"
              type="button"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FraudConfiguration;