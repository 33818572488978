import { UserManager, WebStorageStateStore, Log } from 'oidc-client';
import { IDENTITY_CONFIG } from '../config/authConfig';
import storage from './storage.service';

const logger = console;

export interface IAuthResult {
  accessToken: string;
  idToken: string;
}

export default class AuthService {
  UserManager: UserManager;

  accessToken: string = '';

  constructor() {
    // Logger
    Log.logger = console;
    Log.level = Log.DEBUG;

    const currentDomain = window.location.origin;
    const isCustomeDomain = currentDomain !== process.env.REACT_APP_PUBLIC_URL;

    const customDomainEnvVar = `REACT_APP_${window.location.host.replace(/\./g, '_').toUpperCase()}`;
    const authority = process.env[customDomainEnvVar] ?? process.env.REACT_APP_AUTH_URL;

    const customIdentityConfig = isCustomeDomain ? {
      authority,
      post_logout_redirect_uri: currentDomain,
      silent_redirect_uri: `${currentDomain}/signin-oidc`,
      audience: currentDomain,
      redirect_uri: `${currentDomain}/signin-oidc`,
    } : { redirect_uri: `${process.env.REACT_APP_REDIRECT_URL}` };

    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      ...customIdentityConfig, // overrides IDENTITY_CONFIG if is-custom-domain
      userStore: new WebStorageStateStore({ store: window.localStorage }),
    });

    this.UserManager.events.addUserLoaded((user) => {
      this.accessToken = user.access_token;
      this.setUserInfo({
        accessToken: this.accessToken,
        idToken: user.id_token,
      });
      if (window.location.href.indexOf('signin-oidc') !== -1) {
        this.navigateToScreen();
      }
    });

    this.UserManager.events.addSilentRenewError((error: Error) => {
      logger.info('Silent Renew Error', error.message);
    });

    this.UserManager.events.addAccessTokenExpired(() => {
      logger.info('Token Expired');
      this.signinSilent();
    });
  }

  signinRedirectCallback = () => {
    this.UserManager.signinRedirectCallback()
      .then(() => {
        const locationFromStorage = localStorage.getItem('redirectUri');
        window.location.href = locationFromStorage || '/';
      }).catch((error) => {
        throw new Error('signinRedirectCallback - ', error);
      });
  };

  getUser = async () => {
    const user = await this.UserManager.getUser();
    if (!user) {
      return this.UserManager.signinRedirectCallback();
    }
    return user;
  };

  getStateTokenFromInitialState = async () => {
    const keys = (await this.UserManager.settings.userStore?.getAllKeys()) || [];

    if (!keys.length) return '';

    if (keys.length === 1) return keys[0];

    /**
     * ideally only one item should be in localstorage, but you can have multiple after
     * a failed login attempt.
     */

    let keyArrays: any[] = keys.map((key) => {
      const data = this.UserManager.settings.userStore?.get(key);
      return data;
    });

    keyArrays = await Promise.all(keyArrays);
    keyArrays = keyArrays.map((data) => JSON.parse(data));

    // Get the most recent item
    const [latestStateObject] = keyArrays.sort((a, b) => (a.created > b.created ? -1 : 1));

    return latestStateObject.id;
  };

  parseJwt = (token: string) => {
    const base64Url = token.split('.')[1];
    if (!base64Url) {
      return null;
    }
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  };

  setUserInfo = (authResult: IAuthResult) => {
    const data = this.parseJwt(this.accessToken);
    if (!data) {
      return;
    }
    console.log('authResult==>', authResult);
    this.setSessionInfo(authResult);
    this.setUser(data);
  };

  signinRedirect = () => {
    storage.setItem('redirectUri', window.location.pathname);
    return this.UserManager.signinRedirect({});
  };

  setUser = (data: any) => {
    // eslint-disable-line @typescript-eslint/no-explicit-any
    storage.setItem('userId', data.sub);
  };

  navigateToScreen = () => {
    window.location.replace('/');
  };

  setSessionInfo = (authResult: IAuthResult) => {
    storage.setItem('access_token', authResult.accessToken);
    storage.setItem('id_token', authResult.idToken);
    storage.setEnv();
  };

  isAuthenticated = () => {
    const accessToken = storage.getItem('access_token');
    return !!accessToken;
  };

  signinSilent = () => {
    this.UserManager.signinSilent()
      .then((user) => {
        logger.info('Signed In', user);
      })
      .catch((err: Error) => {
        logger.info(err);
      });
  };

  signinSilentCallback = () => {
    this.UserManager.signinSilentCallback();
  };

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest();
  };

  logout = () => {
    this.UserManager.signoutRedirect({
      access_token_hint: storage.getItem('access_token'),
      id_token_hint: storage.getItem('id_token'),
    });
    this.UserManager.clearStaleState();
  };

  signoutRedirectCallback = () => {
    this.UserManager.signoutRedirectCallback()
      .then(() => {
        localStorage.clear();
      })
      .catch((err) => {
        logger.error({ err });
      });
    this.logout();
    // this.UserManager.clearStaleState();
  };
}
